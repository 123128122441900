import React from 'react'

const Footer = () => {
    return (
        <footer className="text-black bg-blue-900 text-center">
            <div className="flex-grow w-full px-12 py-24 flex flex-col items-center justify-center md:flex-row">
                <div className=" w-full px-4">
                    <h2 className="title-font font-medium text-yellow-500 tracking-widest text-sm mb-3 text-xl">CATEGORIES</h2>
                    <nav className="list-none mb-10">
                        <li>
                            <a className="text-black hover:text-white">Home</a>
                        </li>
                        <li>
                            <a className="text-black hover:text-white">About</a>
                        </li>
                        <li>
                            <a className="text-black hover:text-white">Accredations</a>
                        </li>
                        <li>
                            <a className="text-black hover:text-white">Services</a>
                        </li>
                    </nav>
                </div>
                <div className="w-full px-4">
                    <h2 className="title-font font-medium text-yellow-500 tracking-widest text-xl text-sm mb-3">CATEGORIES</h2>
                    <nav className="list-none mb-10">
                        <li>
                            <a className="text-black hover:text-white">Home</a>
                        </li>
                        <li>
                            <a className="text-black hover:text-white">About</a>
                        </li>
                        <li>
                            <a className="text-black hover:text-white">Accredations</a>
                        </li>
                        <li>
                            <a className="text-black hover:text-white">Services</a>
                        </li>
                    </nav>
                </div>
                <div className="w-full px-4">
                    <h2 className="title-font font-medium text-yellow-500 tracking-widest text-sm text-xl mb-3">CATEGORIES</h2>
                    <nav className="list-none mb-10">
                        <li>
                            <a className="text-black hover:text-white">First Link</a>
                        </li>
                        <li>
                            <a className="text-black hover:text-white">Second Link</a>
                        </li>
                        <li>
                            <a className="text-black hover:text-white">Third Link</a>
                        </li>
                        <li>
                            <a className="text-black hover:text-white">Fourth Link</a>
                        </li>
                    </nav>
                </div>
            </div>
            <div className='footer-copyright text-white'>
                <p className='copyright'> © Copyright UpConstruction.
                </p> <p>All Rights Reserved
                </p>
            </div>
        </footer>
    )
}

export default Footer