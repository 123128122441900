import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import "../css/slider.css";
import Navbar from './Navbar';

const spanStyle = {
    padding: '20px',
    color: 'white',
    fontSize: "80px"
}

const divStyle = {
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center',

    width: "100%",
    height: "900px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
}

const Slider = () => {
    const fadeImages = [
        {
            url: './pexelsC9.jpeg',
            caption: 'Welcome !!',
            description:"Our highly trained team at Roofingmaintence.co.uk are experts in all aspects of roofing from Welsh & Spanish slate, stone, large and small tile products. Our skill set doesn’t stop there! "
        },
        {
            url: './pexelsC0.jpeg',
            caption: 'Second Slide',
            description: "We also specialise in lead and flat roofing from traditional methods to current products like fibreglass and rubber membranes. Our services range from complete re-roofing, dormers, Velux window fitting, rainwater goods to slipped tiles, small repairs and ridge tile cement work. We are proud to be the only accredited roofing company in Greater Manchester and Cheshire to offer all the services needed to cover any request for your roof like our vastly popular ‘Roofing Maintenance Packages’. "
        },
        {
            url: './pexelsC4.jpeg',
            caption: 'Third Slide',
            description: "These packages are available to anyone whether you’re an existing customer of Roofing maintenance.co.uk. or yet take advantage of our services. These are a great way to give you peace of mind throughout the year, safegaurding from what our local weather and climate may throw at your property knowing we have you covered through a plan should a problem arise."
        },
        {
            url: './pexelsC10.jpeg',
            caption: 'Third Slide',
            description: "These packages are available to anyone whether you’re an existing customer of Roofing maintenance.co.uk. or yet take advantage of our services. These are a great way to give you peace of mind throughout the year, safegaurding from what our local weather and climate may throw at your property knowing we have you covered through a plan should a problem arise."
        },
    ];
    return (
        <div className="slide-container">
            <Fade>
                {fadeImages.map((fadeImage, index) => (
                    <div key={index}>
                        <Navbar />
                        <div className="bg-image">
                            <div style={{ ...divStyle, 'backgroundImage': `url(${fadeImage.url})`}} >
                                {/* <p className='image-description text-2xl'>{fadeImage.description}</p> */}
                            </div>
                        </div>
                    </div>
                ))}
            </Fade>
        </div>
    )
}

export default Slider