import React from 'react'
import "../css/flip.css";

const Accredations = () => {
    return (
        <div className='container px-5 py-24 mx-auto'>
            <div className='content-heading text-center '>
                <h2 className='font-bold text-4xl md:text-5xl m-5 text-blue-900'>Accredations</h2>
            </div>
            <div className='flip-flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 md:grid-rows-2 gap-3 '>
                <div className="flip ">
                    <div className='flip-size'>
                        <div className="flip-content">
                            <div className="flip-front">
                                <img src="./pexelsC7.jpeg" />
                            </div>
                            <div className="flip-back text-sm sm:text-base md:text-lg">
                                <p>Being the UK’s largest roofing trade association and spanning over 125 years, our recognition by such a distinguished association ensures complete protection of your relationship with Roofing Maintenance. Our conscientious workmanship alongside such accreditations carries a promise to meet the highest of standards, quote fairly on each occasion and provide technical support before, during, and after all services rendered.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flip ">
                    <div className='flip-size'>
                        <div className="flip-content">
                            <div className="flip-front">
                                <img src="./pexelsC6.jpeg" />
                            </div>
                            <div className="flip-back text-sm sm:text-base md:text-lg">
                                <strong></strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flip ">
                    <div className='flip-size'>
                        <div className="flip-content">
                            <div className="flip-front">
                                <img src="./pexelsC5.jpeg" alt='velux' />
                            </div>
                            <div className="flip-back text-sm sm:text-base md:text-lg">
                                <p>As 5 Star rated certified installers of Velux, we are proud to be able to offer all our existing and future clients any of the products Velux have to offer, fitted to the highest standards and carrying a fully backed guarantee.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flip ">
                    <div className='flip-size'>
                        <div className="flip-content">
                            <div className="flip-front">
                                <img src="./pexelsC8.jpeg" />
                            </div>
                            <div className="flip-back text-sm sm:text-base md:text-lg">
                                <p>This accreditation is gained through due diligence covering on-site inspections, customer records, required standards and financial checks. A Trustmark registered business gives assurance by a government backed scheme ensuring commitment to good customer service, technical competence and trading practice. Such vetting gives clients insurance backed warranties, deposit protection insurance and adhesion to Trustmark’s code of practice.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flip ">
                    <div className='flip-size'>
                        <div className="flip-content">
                            <div className="flip-front">
                                <img src="./pexelsC3.jpeg" />
                            </div>
                            <div className="flip-back text-sm sm:text-base md:text-lg">
                                <p>Compliance to the Fairtrades accreditation ensures we follow the most stringent code of conduct which this distinguished body demands.
                                    Such code of conduct includes full transparency with all supplied contracts, written quotations in advance, never forcefully gaining client signatures, compliance of all laws and building regulations to the letter, punctual approach, respect of clients privacy and rights, completion in allocated time frames, communication at all times of any unexpected changes, safe, clean and tidy results on completion and written guarantees on all services.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Accredations