import React from 'react'
import { MdOutlineEngineering } from "react-icons/md";
import { BiBuildingHouse } from "react-icons/bi";
import { TbBuildingCottage } from "react-icons/tb";
import AnimatedNumbers from "react-animated-numbers";

const Quote = () => {
    return (
        <div className='bg-slate-500 h-[300px] flex flex-col md:flex-row w-full'>
            <div className='bg-blue-900 h-full w-full md:w-1/2 flex flex-col items-center sm:flex-col md:flex-row justify-around text-yellow-500' >
                <div className='flex items-center h-1/2 w-1/4'>
                    <div >
                        <MdOutlineEngineering className='h-1/2 w-20' />
                    </div>
                    <div className='flex flex-col font-bold text-lg sm:text-xl md:text-2xl items-center '>
                        <AnimatedNumbers
                            includeComma
                            animateToNumber={109}
                            fontStyle={{ fontSize: 60 }}
                            configs={[
                                { mass: 1, tension: 220, friction: 100 },
                                { mass: 1, tension: 180, friction: 130 },
                                { mass: 1, tension: 280, friction: 90 },
                                { mass: 1, tension: 180, friction: 135 },
                                { mass: 1, tension: 260, friction: 100 },
                                { mass: 1, tension: 210, friction: 180 },
                            ]}
                        />
                        <p>Expert Workers</p>
                    </div>
                </div>
                <div className='flex items-center h-1/2 w-1/4'>
                    <div >
                        <BiBuildingHouse className='h-1/2 w-20' />
                    </div>
                    <div className='flex flex-col font-bold text-lg sm:text-xl md:text-2xl items-center '>
                        <AnimatedNumbers
                            includeComma
                            animateToNumber={109}
                            fontStyle={{ fontSize: 60 }}
                            configs={[
                                { mass: 1, tension: 220, friction: 100 },
                                { mass: 1, tension: 180, friction: 130 },
                                { mass: 1, tension: 280, friction: 90 },
                                { mass: 1, tension: 180, friction: 135 },
                                { mass: 1, tension: 260, friction: 100 },
                                { mass: 1, tension: 210, friction: 180 },
                            ]}
                        />
                        <p>Happy Clients</p>
                    </div>
                </div>
            </div>
            <div className='bg-yellow-500 h-full w-full md:w-1/2 flex flex-col items-center justify-center  sm:flex-col md:flex-row md:justify-around text-blue-900' >
                <div className='flex items-center h-1/2 w-1/4 my-2'>
                    <div >
                        <TbBuildingCottage className='h-1/2 w-20' />
                    </div>
                    <div className='flex flex-col font-bold text-lg sm:text-xl md:text-2xl items-center '>
                        <AnimatedNumbers
                            includeComma
                            animateToNumber={32}
                            fontStyle={{ fontSize: 60 }}
                            configs={[
                                { mass: 1, tension: 220, friction: 100 },
                                { mass: 1, tension: 180, friction: 130 },
                                { mass: 1, tension: 280, friction: 90 },
                                { mass: 1, tension: 180, friction: 135 },
                                { mass: 1, tension: 260, friction: 100 },
                                { mass: 1, tension: 210, friction: 180 },
                            ]}
                        />
                        <p>Completed Projects</p>
                    </div>
                </div>
                <div className='flex items-center h-1/2 w-1/4 my-2'>
                    <div >
                        <BiBuildingHouse className='h-1/2 w-20' />
                    </div>
                    <div className='flex flex-col font-bold text-lg sm:text-xl md:text-2xl items-center '>
                        <AnimatedNumbers
                            includeComma
                            animateToNumber={20}
                            fontStyle={{ fontSize: 60 }}
                            configs={[
                                { mass: 1, tension: 220, friction: 100 },
                                { mass: 1, tension: 180, friction: 130 },
                                { mass: 1, tension: 280, friction: 90 },
                                { mass: 1, tension: 180, friction: 135 },
                                { mass: 1, tension: 260, friction: 100 },
                                { mass: 1, tension: 210, friction: 180 },
                            ]}
                        />
                        <p>Running Projects</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Quote
