import React from 'react'
import { BiTime } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import { FiPhone } from "react-icons/fi";

const Top = () => {
    return (
        <div className='bg-yellow-500 h-auto md:h-[100px]'>
            <div className='flex items-center justify-around flex-col sm:flex-col md:flex-row'>
                <div className='m-5 animate__animated  animate__slideInLeft'>
                    <h4 className='text-blue-900 text-3xl font-bold'>UpConstruction</h4>
                </div>
                <div className='flex flex-col sm:flex-col md:flex-row animate__animated  animate__slideInRight'>
                    <div className='flex m-2'>
                        <BiTime className='w-20 h-10' />
                        <div className='flex flex-col'>
                            <p className='text-lg'>Opening hours</p>
                            <p>Mon - Fri, 8:00 - 9:00</p>
                        </div>
                    </div>
                    <div className='flex m-2'>
                        <FiPhone className='w-20 h-10' />
                        <div className='flex flex-col'>
                            <p className='text-lg'>Call Us                            </p>
                            <p>+012 345 6789</p>
                        </div>
                    </div>
                    <div className='flex m-2'>
                        <AiOutlineMail className='w-20 h-10' />
                        <div className='flex flex-col'>
                            <p className='text-lg'>Email Us                            </p>
                            <p>info@example.coms </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Top
