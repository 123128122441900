import React from 'react'

const About = () => {
  return (
    <section className="text-gray-600 body-font overflow-hidden">
      <div className="container px-5 py-24 mx-auto">
        <div className="lg:w-4/5 mx-auto flex flex-wrap">
          <img alt="ecommerce" className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded" src="./pexelsC1.jpeg" />
          <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
            <h2 className="text-sm title-font text-gray-500 tracking-widest">UpConstruction</h2>
            <h1 className="text-gray-900 text-3xl title-font font-medium mb-1 uppercase">about us</h1>
            <p className="leading-relaxed">Roofing Maintenance have a vast plethora of knowledge spanning over 40+ yrs. With most of our staff having experienced over 25yrs in the industry, you are in safe hands.
              Our approach is simple “Be the best!” With this said, we are ever-adapting to new techniques, products, specifications and training that’s available for our staff within our industry, always remaining on-par with advancing technologies. We are proud to be accredited with some of the best well known industry bodies, thus giving you peace of mind when choosing our company for any of your roofing requirements.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About