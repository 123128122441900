import React from 'react'

const Navbar = () => {
    return (
        <header className="text-blue-900 m-5">
            <div className="mx-auto flex flex-wrap justify-between p-2 flex-col md:flex-row ">
                <nav className="md:ml-auto flex text-blue-900 flex-wrap items-center text-base justify-center">
                    <a className="mr-5 border-b-4 hover:text-blue-900 text-2xl">Home</a>
                    <a className="mr-5 border-b-4 hover:text-blue-900 text-2xl">About</a>
                    <a className="mr-5  border-b-4 hover:text-blue-900 text-2xl">FAQs</a>
                    <a className="mr-5 border-b-4 hover:text-blue-900 text-2xl">Contact Us</a>
                    <a className="mr-5 border-b-4 hover:border-b-yellow-600 hover:text-blue-900 text-2xl">Services</a>
                </nav>
            </div>
        </header>
    )
}

export default Navbar