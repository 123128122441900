import React from 'react'
import { MdOutlineRoofing, MdConstruction } from "react-icons/md";
import { BiRightArrowAlt } from "react-icons/bi";
import { AiOutlineHome, AiOutlineSetting } from "react-icons/ai";

const Services = () => {
    return (
        <div className='pt-5 m-5'>
            <div className='my-5 text-center'>
                <h1 className='text-yellow-400 text-lg md:text-xl'> Our Services</h1>
                <p className='text-blue-900 md:text-6xl text-3xl font-bold'>We provide services</p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 md:grid-rows-3 gap-3 lg:mt-8 lg::mx-4 lg:p-2">
                <div className="flex justify-center">
                    <div className='flex items-center px-4 bg-white  mb-4'>
                        <div className='flex items-center justify-center text-blue-900'>
                            <AiOutlineSetting className='rounded-2xl h-10 w-10 m-2' />
                        </div>
                        <div className='service-content'>
                            <h4 className='font-bold text-2xl text-blue-900'>Tile roofing</h4>
                            <p>Come in various shapes and sizes and are a cheaper alternative to natural products.</p>
                            <div className='text-violet-400 flex'>
                                <a href='#learn-more'>Learn More</a>
                                <BiRightArrowAlt className='pt-1' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex items-center px-4 bg-white  mb-4'>
                    <div className='flex items-center justify-center text-blue-900 '>
                        <MdOutlineRoofing className='rounded-2xl h-10 w-10 m-2' />
                    </div>
                    <div className='service-content'>
                        <h4 className='font-bold text-2xl text-blue-900'>Slate roofing</h4>
                        <p>A very popular product and still one of the nation’s favourite, with some of the best roofing being produced in slate there is no wonder why. Slate can be cut to all shapes and angles by the roofer whilst remaining pleasing to the eye. </p>
                        <div className='text-violet-400 flex'>
                            <a href='#learn-more'>Learn More</a>
                            <BiRightArrowAlt className='pt-1' />
                        </div>
                    </div>
                </div>
                <div className='flex items-center px-4 bg-white  mb-4'>
                    <div className='flex items-center justify-center text-blue-900 '>
                        <MdConstruction className='rounded-2xl h-10 w-10 m-2' />
                    </div>
                    <div className='service-content'>
                        <h4 className='font-bold text-2xl text-blue-900'>Stone Roofing</h4>
                        <p>A thing of beauty, however, this product is mainly seen on older or listed properties such as stone-built properties from cottages to stately homes and not commonly used anymore unless requested by local building authorities. </p>
                        <div className='text-violet-400 flex'>
                            <a href='#learn-more'>Learn More</a>
                            <BiRightArrowAlt className='pt-1' />
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-center px-4 bg-white mb-4'>
                    <div className='flex items-center justify-center text-blue-900 '>
                        <AiOutlineHome className='rounded-2xl h-10 w-10 m-2' />
                    </div>
                    <div className='service-content'>
                        <h4 className='font-bold text-2xl text-blue-900'>Flat Roofing</h4>
                        <p>Generally used on commercial buildings, single story extensions and large dormers.</p>
                        <div className='text-violet-400 flex'>
                            <a href='#learn-more'>Learn More</a>
                            <BiRightArrowAlt className='pt-1' />
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-centerpx-4 bg-white mb-4'>
                    <div className='flex items-center justify-center text-blue-900 '>
                        <AiOutlineSetting className='rounded-2xl h-10 w-10 m-2' />
                    </div>
                    <div className='service-content'>
                        <h4 className='font-bold text-2xl text-blue-900'>Guttering and Downspouts</h4>
                        <p>Rainwater products vary in all shapes and sizes and difference in material forms from Aluminium to plastic.</p>
                        <div className='text-violet-400 flex'>
                            <a href='#learn-more'>Learn More</a>
                            <BiRightArrowAlt className='pt-1' />
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-centerpx-4 bg-white mb-4'>
                    <div className='flex items-center justify-center text-blue-900 '>
                        <MdOutlineRoofing className='rounded-2xl h-10 w-10 m-2' />
                    </div>
                    <div className='service-content'>
                        <h4 className='font-bold text-2xl text-blue-900'>Chimney stack removals</h4>
                        <p>Becoming more and more popular today as the use of open fires continue to decline.</p>
                        <div className='text-violet-400 flex'>
                            <a href='#learn-more'>Learn More</a>
                            <BiRightArrowAlt className='pt-1' />
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-centerpx-4 bg-white  mb-4'>
                    <div className='flex items-center justify-center text-blue-900 '>
                        <AiOutlineHome className='rounded-2xl h-10 w-10 m-2' />
                    </div>
                    <div className='service-content'>
                        <h4 className='font-bold text-2xl text-blue-900'>Chimney stack repairs</h4>
                        <p>From flashing to soakers and re-pointing to re-bedding of the pots, we are able to fulfil all aspects of chimney stack repairs.</p>
                        <div className='text-violet-400 flex'>
                            <a href='#learn-more'>Learn More</a>
                            <BiRightArrowAlt className='pt-1' />
                        </div>
                    </div>
                </div>
                <div className='flex items-center px-4 bg-white  mb-4'>
                    <div className='flex items-center justify-center text-blue-900 '>
                        <MdConstruction className='rounded-2xl h-10 w-10 m-2' />
                    </div>
                    <div className='service-content'>
                        <h4 className='font-bold text-2xl text-blue-900'>General maintenance</h4>
                        <p>Covering a vast array of possibilities from cement work, loose/lost/broken tiles or slates, replacement/re-bedding ridge tiles to flashing and soakers, facia’s and soffits.</p>
                        <div className='text-violet-400 flex'>
                            <a href='#learn-more'>Learn More</a>
                            <BiRightArrowAlt className='pt-1' />
                        </div>
                    </div>
                </div>
                <div className='flex items-center md:h-auto px-4 bg-white  mb-4'>
                    <div className='flex items-center justify-center text-blue-900 '>
                        <AiOutlineSetting className='rounded-2xl h-10 w-10 m-2' />
                    </div>
                    <div className='service-content'>
                        <h4 className='font-bold text-2xl text-blue-900'>Velux Installation</h4>
                        <p>Being a Velux certified installer coupled with many years of installing all desirable versions of Velux products makes us market leaders with an ever-increasing demand for a continually expanding range of advanced products. Incorporating products such as the popular centre-pivot and the top-hung alongside more technical lines like the Velux Integra, Velux Active, Velux EdgeGlow sun tunnels to name a few. Our expertise and certification ensure the most professional of installation’s alongside guaranteed warranties for both product and workmanship.</p>
                        <div className='text-violet-400 flex'>
                            <a href='#learn-more'>Learn More</a>
                            <BiRightArrowAlt className='pt-1' />
                        </div>
                    </div>
                </div>
                <div className='flex items-center px-4 bg-white  mb-4'>
                    <div className='flex items-center justify-center text-blue-900 '>
                        <MdOutlineRoofing className='rounded-2xl h-10 w-10 m-2' />
                    </div>
                    <div className='service-content'>
                        <h4 className='font-bold text-2xl text-blue-900'>New Roofs</h4>
                        <p>Our roofing personnel are skilled and accredited in all aspects from development of initial design, technical input to manufacture and installation of the chosen product.</p>
                        <div className='text-violet-400 flex'>
                            <a href='#learn-more'>Learn More</a>
                            <BiRightArrowAlt className='pt-1' />
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-center  mdh-auto px-4 bg-white  mb-4'>
                    <div className='flex items-center justify-center text-blue-900 '>
                        <MdConstruction className='rounded-2xl h-10 w-10 m-2' />
                    </div>
                    <div className='service-content'>
                        <h4 className='font-bold text-2xl text-blue-900'>Flat to pitched roof conversions</h4>
                        <p>There are a whole host of benefits to converting your flat roof to a pitch roof. Not only can it be more aesthetically pleasing to owners but more appealing to potential buyers.</p>
                        <div className='text-violet-400 flex'>
                            <a href='#learn-more'>Learn More</a>
                            <BiRightArrowAlt className='pt-1' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services