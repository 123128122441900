import React from 'react'
import { FiPhone } from "react-icons/fi";
import { BiBuildingHouse } from "react-icons/bi";
import { TbBuildingCottage } from "react-icons/tb";


const Header = () => {
    return (
        <div className='bg-slate-200 h-auto md:h-[400px] flex flex-col md:flex-row animate__animated animate__slideInUp'>
            <div className='w-full md:w-1/3 bg-blue-900  flex items-center justify-center  text-yellow-500 ' >
                <div>
                    <BiBuildingHouse className='h-10 w-20' />
                </div>
                <div className='flex-col flex w-2/4'>
                    <h1 className='text-3xl'>24/7 Support</h1>
                    <p className='text-xl'>Lorem ipsum dolor sit amet elit. Phasus nec pretim ornare velit non</p>
                </div>
            </div>
            <div className='bg-yellow-500 flex items-center justify-center w-full md:w-1/3 text-white'>
                <div>
                    <TbBuildingCottage className='h-10 w-20' />
                </div>
                <div className='flex-col flex w-2/4'>
                    <h1 className='text-3xl'>24/7 Support</h1>
                    <p className='text-xl'>Lorem ipsum dolor sit amet elit. Phasus nec pretim ornare velit non</p>
                </div>
            </div>
            <div className='bg-blue-900 flex items-center justify-center w-full md:w-1/3 text-yellow-500 '>
                <div>
                    <FiPhone className='h-10 w-20' />
                </div>
                <div className='flex-col flex w-2/4'>
                    <h1 className='text-3xl'>24/7 Support</h1>
                    <p className='text-xl'>Lorem ipsum dolor sit amet elit. Phasus nec pretim ornare velit non</p>
                </div>
            </div>
        </div>
    )
}

export default Header
