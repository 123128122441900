import Top from "./components/top";
import Slider from "./components/Slider";
import About from "./components/About";
import Quote from "./components/quote";
import Accredations from "./components/Accredations";
import Services from "./components/Services";
import Footer from "./components/Footer";
import Header from "./components/Header";
import AnimateHeader from "./components/AnimateHeader";


function App() {
 
  return (
    <div className="App">
      <Top />
      <Slider />
      <Header/>
      {/* <AnimateHeader/> */}
      <About />
      <Quote />
      <Accredations />
      <Services />
      <Footer />
    </div>
  );
}

export default App;
